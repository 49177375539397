import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';


// document.addEventListener('contextmenu', (e) => {
//   e.preventDefault();
// });

const Sabdhan = (props) => (

  <div id="sabdhan">
    <div id="baani"></div>
    { props.children }
  </div>
)




ReactDOM.render(
  <Sabdhan>
    <App/>
  </Sabdhan>, document.getElementById('root')
);

serviceWorker.unregister();