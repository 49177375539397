export const storage  = 'lsc'
// export const server   = 'http://27.147.144.138:2233';
// export const server   = 'http://118.67.218.26:8679/lsc';
// export const server   = 'http://109.123.232.86:8080';
// export const server   = 'http://27.147.144.138:2233';
//export const server   = 'http://167.71.199.212:8990';
export const server   = 'http://109.123.232.86:8080';
// amader server
// export const server   = 'http://192.168.1.40:8181'; 
// production server
// export const server   = 'http://68.183.232.101:8181';
// export const server   = 'http://118.67.218.26:8080';
export const local    = '/';
export const basename = '/';

export const statuses = {
  "APPLICANT": [
    { label: 'সকল আবেদন', value: ['DRAFT', 'NEW', 'HOLD', 'REJECTED', 'FORWARDED', 'SUBMITTED', 'DELETED', 'AP_CORRECTION', 'PA_CORRECTION', 'FA_CORRECTION', 'PA_CHECKED', 'FA_CHECKED', 'DGFI_CHECKED', 'DGFI_APPROVED', 'FA_APPROVED', 'FINAL_APPROVED'] },
    { label: 'ড্রাফট', value: ['DRAFT'] },
    { label: 'নতুন', value: ['NEW'] },
    { label: 'হোল্ড', value: ['HOLD'] },
    { label: 'প্রত্যাখ্যাত', value: ['REJECTED'] },
    { label: 'ফরওয়ার্ডেড', value: ['FORWARDED'] },
    { label: 'সাবমিটেড', value: ['SUBMITTED'] },
    { label: 'মুছে ফেলা হয়েছে', value: ['DELETED'] },
    { label: 'সংশোধন', value: ['AP_CORRECTION', 'PA_CORRECTION', 'FA_CORRECTION'] },
    { label: 'নিরীক্ষিত', value: ['PA_CHECKED', 'FA_CHECKED', 'DGFI_CHECKED'] },
    { label: 'অনুমোদিন্ত', value: ['DGFI_APPROVED', 'FA_APPROVED', 'FINAL_APPROVED'] },
  ],
  "PRIMARY_AGENCY": [
    { label: 'সকল আবেদন', value: ['NEW', 'PA_CHECKED', 'FORWARDED', 'SUBMITTED', 'DGFI_APPROVED', 'FA_APPROVED', 'FINAL_APPROVED', 'AP_CORRECTION', 'PA_CORRECTION', 'REJECTED'] },
    { label: 'নতুন', value: ['NEW'] },
    { label: 'নিরীক্ষিত', value: ['PA_CHECKED'] },
    { label: 'ফরওয়ার্ডেড', value: ['FORWARDED'] },
    { label: 'সাবমিটেড', value: ['SUBMITTED'] },
    { label: 'অনুমোদিত', value: ['DGFI_APPROVED', 'FA_APPROVED', 'FINAL_APPROVED'] },
    { label: 'সংশোধন', value: ['AP_CORRECTION', 'PA_CORRECTION'] },
    { label: 'প্রত্যাখ্যাত', value: ['REJECTED'] },
  ],
  "FORWARDING_AGENCY": [
    { label: 'সকল আবেদন', value: ['FORWARDED', 'FA_CHECKED', 'SUBMITTED', 'DGFI_APPROVED', 'FA_APPROVED', 'FINAL_APPROVED', 'PA_CORRECTION', 'FA_CORRECTION', 'REJECTED'] },
    { label: 'ফরওয়ার্ডেড', value: ['FORWARDED'] },
    { label: 'নিরীক্ষিত', value: ['FA_CHECKED'] },
    { label: 'সাবমিটেড', value: ['SUBMITTED'] },
    { label: 'অনুমোদিত', value: ['DGFI_APPROVED', 'FA_APPROVED', 'FINAL_APPROVED'] },
    { label: 'সংশোধন', value: ['PA_CORRECTION', 'FA_CORRECTION'] },
    { label: 'প্রত্যাখ্যাত', value: ['REJECTED'] },
  ],
  "ADMIN": [
    // { label: 'সকল আবেদন', value: ['DRAFT', 'NEW', 'HOLD', 'REJECTED', 'FORWARDED', 'SUBMITTED', 'DELETED', 'AP_CORRECTION', 'PA_CORRECTION', 'FA_CORRECTION', 'PA_CHECKED', 'FA_CHECKED', 'DGFI_CHECKED', 'DGFI_APPROVED', 'FA_APPROVED', 'FINAL_APPROVED'] },
    { label: 'সকল আবেদন', value: ['SUBMITTED', 'DGFI_CHECKED', 'DGFI_APPROVED', 'FINAL_APPROVED', 'AP_CORRECTION', 'PA_CORRECTION', 'FA_CORRECTION'] },
    { label: 'সাবমিটেড', value: ['SUBMITTED'] },
    { label: 'নিরীক্ষিত', value: ['DGFI_CHECKED'] },
    { label: 'অনুমোদিত', value: ['DGFI_APPROVED', 'FINAL_APPROVED'] },
    { label: 'সংশোধন', value: ['AP_CORRECTION', 'PA_CORRECTION','FA_CORRECTION'] },
    { label: 'প্রত্যাখ্যাত', value: ['REJECTED'] },
  ]
}

export const statusColor = {
  'DRAFT': 'yellow',
  'NEW': 'yellow',
  'HOLD': 'red',
  'REJECTED': 'red',
  'FORWARDED': 'olive',
  'SUBMITTED': 'olive',
  'DELETED': 'red',
  'AP_CORRECTION': 'red',
  'PA_CORRECTION': 'red',
  'FA_CORRECTION': 'red',
  'PA_CHECKED': 'teal',
  'FA_CHECKED': 'teal',
  'DGFI_CHECKED': 'teal',
  'DGFI_APPROVED': 'green',
  'FA_APPROVED': 'green',
  'FINAL_APPROVED': 'green',
}

export const statusColorApplicant = {
  'DRAFT': 'yellow',
  'NEW': 'yellow',
  'HOLD': 'yellow',
  'REJECTED': 'yellow',
  'FORWARDED': 'yellow',
  'SUBMITTED': 'yellow',
  'DELETED': 'yellow',
  'AP_CORRECTION': 'red',
  'PA_CORRECTION': 'red',
  'FA_CORRECTION': 'red',
  'PA_CHECKED': 'yellow',
  'FA_CHECKED': 'yellow',
  'DGFI_CHECKED': 'yellow',
  'DGFI_APPROVED': 'yellow',
  'FA_APPROVED': 'yellow',
  'FINAL_APPROVED': 'green',
}

export const exclude = {
  "ADMIN": ['DRAFT', 'NEW', 'FORWARDED'],
  // "ADMIN": ['DRAFT', 'NEW', 'FORWARDED', 'AP_CORRECTION', 'PA_CORRECTION'],
  "PRIMARY_AGENCY": ['DRAFT', 'AP_CORRECTION', 'FA_CORRECTION'],
  "FORWARDING_AGENCY": ['DRAFT', 'NEW', 'AP_CORRECTION', 'PA_CORRECTION'],
  "APPLICANT": []
};

export const forms    = {
  "FARM_REGISTRATION": {
    id: 1,
    bn: "ঠিকাদারী প্রতিষ্ঠান নিবন্ধন",
    route: "/forms/enroll/view/"
  },
  "FARM_RENEW": {
    id: 2,
    bn: "ঠিকাদারী প্রতিষ্ঠান নবায়ন",
    route: "/forms/renew/view/"
  },
  "GOLF_CLUB": {
    id: 7,
    bn: "গল্‌ফ প্রতিপাদন",
    route: "/forms/golf-verification/view/"
  },
  "CIVIL_BIOGRAPHIC": {
    id: 4,
    bn: "বেসামরিক জীবনী তথ্য",
    route: "/forms/civilian/view/"
  },
  "PROPERTY_TRADING": {
    id: 5,
    bn: "ফ্ল্যাট/ প্লট ক্রয়ের আবেদন",
    route: "/forms/flat-and-plot/view/"
  },
  // "FREQUENCY_ALLOCATION": {
  //   id: 3,
  //   bn: "তরঙ্গ বরাদ্দের জন্য আবেদন",
  //   route: "/forms/frequency/view/"
  // },
  // "EVENT_CLEARANCE": {
  //   id: 6,
  //   bn: "অনুষ্ঠানে অংশগ্রহণকারীদের তথ্যাবলী",
  //   route: "/forms/bulk-entry/view/"
  // },
}

export const update    = {
  "FARM_REGISTRATION": {
    bn: "ঠিকাদারী প্রতিষ্ঠান নিবন্ধন",
    route: "/forms/enroll/update/"
  },
  "FARM_RENEW": {
    bn: "ঠিকাদারী প্রতিষ্ঠান নবায়ন",
    route: "/forms/renew/update/"
  },
  "GOLF_CLUB": {
    bn: "গল্‌ফ প্রতিপাদন",
    route: "/forms/golf-verification/update/"
  },
  "CIVIL_BIOGRAPHIC": {
    bn: "বেসামরিক জীবনী তথ্য",
    route: "/forms/civilian/update/"
  },
  "PROPERTY_TRADING": {
    bn: "ফ্ল্যাট/ প্লট ক্রয়ের আবেদন",
    route: "/forms/flat-and-plot/update/"
  },
  // "FREQUENCY_ALLOCATION": {
  //   bn: "তরঙ্গ বরাদ্দের জন্য আবেদন",
  //   route: "/forms/frequency/update/"
  // },
  // "EVENT_CLEARANCE": {
  //   bn: "অনুষ্ঠানে অংশগ্রহণকারীদের তথ্যাবলী",
  //   route: "/forms/bulk-entry/update/"
  // },
}

export const agencyType = [
  {
    key: "PRIMARY_AGENCY",
    text: "প্রাইমারি এজেন্সি",
    value: "PRIMARY_AGENCY"
  },
  {
    key: "FORWARDING_AGENCY",
    text: "ফরওয়ার্ডিং এজেন্সি",
    value: "FORWARDING_AGENCY"
  }
]

export const armyStation = [
  { 
    key: `আর্মি`,
    text: `আর্মি`,
    value: 'আর্মি',
  },
  { 
    key: `নেভি`,
    text: `নেভি`,
    value: 'নেভি',
  },
  { 
    key: `এয়ার ফোর্স`,
    text: `এয়ার ফোর্স`,
    value: 'এয়ার ফোর্স',
  },
]

export const typeOfProperty = [
  {
    key: `ফ্ল্যাট`,
    text: `ফ্ল্যাট`,
    value: `FLAT`
  },
  {
    key: `প্লট`,
    text: `প্লট`,
    value: `PLOT`
  },
]

export const relationship = [
  {
    key: "স্বামী",
    text: "স্বামী",
    value: "স্বামী",
  },
  {
    key: "স্ত্রী",
    text: "স্ত্রী",
    value: "স্ত্রী",
  },
  {
    key: "সন্তান",
    text: "সন্তান",
    value: "সন্তান",
  },
  {
    key: "পুত্র",
    text: "পুত্র",
    value: "পুত্র",
  },
  {
    key: "কন্যা",
    text: "কন্যা",
    value: "কন্যা",
  },
  {
    key: "পিতা",
    text: "পিতা",
    value: "পিতা",
  },
  {
    key: "মাতা",
    text: "মাতা",
    value: "মাতা",
  },
  {
    key: "শ্বশুর",
    text: "শ্বশুর",
    value: "শ্বশুর",
  },
  {
    key: "শাশুড়ি",
    text: "শাশুড়ি",
    value: "শাশুড়ি",
  },
  {
    key: "ভাই",
    text: "ভাই",
    value: "ভাই",
  },
  {
    key: "বোন",
    text: "বোন",
    value: "বোন",
  },
  {
    key: "ভগ্নীপতি",
    text: "ভগ্নীপতি",
    value: "ভগ্নীপতি",
  },
  {
    key: "ভাবী",
    text: "ভাবী",
    value: "ভাবী",
  },
  {
    key: "শ্যালক",
    text: "শ্যালক",
    value: "শ্যালক",
  },
  {
    key: "শ্যালিকা",
    text: "শ্যালিকা",
    value: "শ্যালিকা",
  },
  {
    key: "চাচা",
    text: "চাচা",
    value: "চাচা",
  },
  {
    key: "চাচী",
    text: "চাচী",
    value: "চাচী",
  },
  {
    key: "মামা",
    text: "মামা",
    value: "মামা",
  },
  {
    key: "মামী",
    text: "মামী",
    value: "মামী",
  },
  {
    key: "খালু",
    text: "খালু",
    value: "খালু",
  },
  {
    key: "খালা",
    text: "খালা",
    value: "খালা",
  },
  {
    key: "ফুফা",
    text: "ফুফা",
    value: "ফুফা",
  },
  {
    key: "ফুফি",
    text: "ফুফি",
    value: "ফুফি",
  },
  {
    key: "এনও",
    text: "এনও",
    value: "এনও",
  },
  // {
  //   key: `অন্যান্য`,
  //   text: `অন্যান্য`,
  //   value: `অন্যান্য`,
  // },
]

export const nationality = [
  {
    key: `বাংলাদেশী`,
    text: `বাংলাদেশী`,
    value: `বাংলাদেশী`,
  },
  {
    key: `আফগান`,
    text: `আফগান`,
    value: `আফগান`,
  },
  {
    key: `আল্বেনিয়ান`,
    text: `আল্বেনিয়ান`,
    value: `আল্বেনিয়ান`,
  },
  {
    key: `অস্ট্রেলিয়ান`,
    text: `অস্ট্রেলিয়ান`,
    value: `অস্ট্রেলিয়ান`,
  },
  {
    key: `আস্ট্রিয়ান`,
    text: `আস্ট্রিয়ান`,
    value: `আস্ট্রিয়ান`,
  },
  {
    key: `বেলজিয়ান`,
    text: `বেলজিয়ান`,
    value: `বেলজিয়ান`,
  },
  {
    key: `ব্রাজিলিয়ান`,
    text: `ব্রাজিলিয়ান`,
    value: `ব্রাজিলিয়ান`,
  },
  {
    key: `ক্যানাডিয়ান`,
    text: `ক্যানাডিয়ান`,
    value: `ক্যানাডিয়ান`,
  },
  {
    key: `চাইনিজ`,
    text: `চাইনিজ`,
    value: `চাইনিজ`,
  },
  {
    key: `ডানিস`,
    text: `ডানিস`,
    value: `ডানিস`,
  },
  {
    key: `ইংলিশ`,
    text: `ইংলিশ`,
    value: `ইংলিশ`,
  },
  {
    key: `ফিনিশ`,
    text: `ফিনিশ`,
    value: `ফিনিশ`,
  },
  {
    key: `ফ্রেঞ্চ`,
    text: `ফ্রেঞ্চ`,
    value: `ফ্রেঞ্চ`,
  },
  {
    key: `জার্মান`,
    text: `জার্মান`,
    value: `জার্মান`,
  },
  {
    key: `ভারতীয়`,
    text: `ভারতীয়`,
    value: `ভারতীয়`,
  },
  {
    key: `ইরাকী`,
    text: `ইরাকী`,
    value: `ইরাকী`,
  },
  {
    key: `ইতালিয়ান`,
    text: `ইতালিয়ান`,
    value: `ইতালিয়ান`,
  },
  {
    key: `জাপানিজ`,
    text: `জাপানিজ`,
    value: `জাপানিজ`,
  },
  {
    key: `ডাচ`,
    text: `ডাচ`,
    value: `ডাচ`,
  },
  {
    key: `পাকিস্তানী`,
    text: `পাকিস্তানী`,
    value: `পাকিস্তানী`,
  },
  {
    key: `পোলিশ`,
    text: `পোলিশ`,
    value: `পোলিশ`,
  },
  {
    key: `রুশ`,
    text: `রুশ`,
    value: `রুশ`,
  },
  {
    key: `ইথিওপিয়ান`,
    text: `ইথিওপিয়ান`,
    value: `ইথিওপিয়ান`,
  },
  {
    key: `ফিজিয়ান`,
    text: `ফিজিয়ান`,
    value: `ফিজিয়ান`,
  },
  {
    key: `ঘানাইয়ান`,
    text: `ঘানাইয়ান`,
    value: `ঘানাইয়ান`,
  },
  {
    key: `গ্রীক`,
    text: `গ্রীক`,
    value: `গ্রীক`,
  },
  {
    key: `গুয়েতেমালান`,
    text: `গুয়েতেমালান`,
    value: `গুয়েতেমালান`,
  },
  {
    key: `হাইতিয়ান`,
    text: `হাইতিয়ান`,
    value: `হাইতিয়ান`,
  },
  {
    key: `হন্দুরান`,
    text: `হন্দুরান`,
    value: `হন্দুরান`,
  },
  {
    key: `হাঙ্গেরিয়ান`,
    text: `হাঙ্গেরিয়ান`,
    value: `হাঙ্গেরিয়ান`,
  },
  {
    key: `আইসল্যান্ডিক`,
    text: `আইসল্যান্ডিক`,
    value: `আইসল্যান্ডিক`,
  },
  {
    key: `ইন্দোনেশিয়ান`,
    text: `ইন্দোনেশিয়ান`,
    value: `ইন্দোনেশিয়ান`,
  },
  {
    key: `ইরানীয়ান`,
    text: `ইরানীয়ান`,
    value: `ইরানীয়ান`,
  },
  {
    key: `পেরুভীয়ান`,
    text: `পেরুভীয়ান`,
    value: `পেরুভীয়ান`,
  },
  {
    key: `ফিলিপাইন`,
    text: `ফিলিপাইন`,
    value: `ফিলিপাইন`,
  },
  {
    key: `পর্তুগিজ`,
    text: `পর্তুগিজ`,
    value: `পর্তুগিজ`,
  },
  {
    key: `রোমানিয়ান`,
    text: `রোমানিয়ান`,
    value: `রোমানিয়ান`,
  },
  {
    key: `স্কটিশ`,
    text: `স্কটিশ`,
    value: `স্কটিশ`,
  },
  {
    key: `সেনেগালিজ`,
    text: `সেনেগালিজ`,
    value: `সেনেগালিজ`,
  },
  {
    key: `সার্বিয়ান`,
    text: `সার্বিয়ান`,
    value: `সার্বিয়ান`,
  },
  {
    key: `সিঙ্গাপুরিয়ান`,
    text: `সিঙ্গাপুরিয়ান`,
    value: `সিঙ্গাপুরিয়ান`,
  },
  {
    key: `স্লোভাক`,
    text: `স্লোভাক`,
    value: `স্লোভাক`,
  },
  {
    key: `সাউথ আফ্রিকান`,
    text: `সাউথ আফ্রিকান`,
    value: `সাউথ আফ্রিকান`,
  },
  {
    key: `শ্রী লঙ্কান`,
    text: `শ্রী লঙ্কান`,
    value: `শ্রী লঙ্কান`,
  },
  {
    key: `সুদানিজ`,
    text: `সুদানিজ`,
    value: `সুদানিজ`,
  },
  {
    key: `সুইডিশ`,
    text: `সুইডিশ`,
    value: `সুইডিশ`,
  },
  {
    key: `সুইস`,
    text: `সুইস`,
    value: `সুইস`,
  },
  {
    key: `তাইওয়ানিজ`,
    text: `তাইওয়ানিজ`,
    value: `তাইওয়ানিজ`,
  },
  {
    key: `তাজিকিস্থানি`,
    text: `তাজিকিস্থানি`,
    value: `তাজিকিস্থানি`,
  },
  {
    key: `আলজেরিয়ান`,
    text: `আলজেরিয়ান`,
    value: `আলজেরিয়ান`,
  },
  {
    key: `বলিভিয়ান`,
    text: `বলিভিয়ান`,
    value: `বলিভিয়ান`,
  },
  {
    key: `ইজিপ্সিয়ান`,
    text: `ইজিপ্সিয়ান`,
    value: `ইজিপ্সিয়ান`,
  },
  {
    key: `কোরিয়ান`,
    text: `কোরিয়ান`,
    value: `কোরিয়ান`,
  },
  {
    key: `স্প্যানিশ`,
    text: `স্প্যানিশ`,
    value: `স্প্যানিশ`,
  },
  {
    key: `সিরিয়ান`,
    text: `সিরিয়ান`,
    value: `সিরিয়ান`,
  },
  {
    key: `থাই`,
    text: `থাই`,
    value: `থাই`,
  },
  {
    key: `টার্কিশ`,
    text: `টার্কিশ`,
    value: `টার্কিশ`,
  },
  {
    key: `ব্রিটিশ`,
    text: `ব্রিটিশ`,
    value: `ব্রিটিশ`,
  },
  {
    key: `অ্যামেরিকান`,
    text: `অ্যামেরিকান`,
    value: `অ্যামেরিকান`,
  },
  {
    key: `আর্জেন্টাইন`,
    text: `আর্জেন্টাইন`,
    value: `আর্জেন্টাইন`,
  },
  {
    key: `মালয়েশিয়ান`,
    text: `মালয়েশিয়ান`,
    value: `মালয়েশিয়ান`,
  },
  {
    key: `টঙ্গান`,
    text: `টঙ্গান`,
    value: `টঙ্গান`,
  },
  {
    key: `তিউনিসিআন`,
    text: `তিউনিসিআন`,
    value: `তিউনিসিআন`,
  },
  {
    key: `ইউক্রেইনি`,
    text: `ইউক্রেইনি`,
    value: `ইউক্রেইনি`,
  },
  {
    key: `এমিরাতি`,
    text: `এমিরাতি`,
    value: `এমিরাতি`,
  },
  {
    key: `উরুগুয়ান`,
    text: `উরুগুয়ান`,
    value: `উরুগুয়ান`,
  },
  {
    key: `ভেনিজুয়েলান`,
    text: `ভেনিজুয়েলান`,
    value: `ভেনিজুয়েলান`,
  },
  {
    key: `ভিয়েতনামিজ`,
    text: `ভিয়েতনামিজ`,
    value: `ভিয়েতনামিজ`,
  },
  {
    key: `ওয়েলশ`,
    text: `ওয়েলশ`,
    value: `ওয়েলশ`,
  },
  {
    key: `জাম্বিয়ান`,
    text: `জাম্বিয়ান`,
    value: `জাম্বিয়ান`,
  },
  {
    key: `জিম্বাবুয়েন`,
    text: `জিম্বাবুয়েন`,
    value: `জিম্বাবুয়েন`,
  },
  {
    key: `আইরিশ`,
    text: `আইরিশ`,
    value: `আইরিশ`,
  },
  {
    key: `ইজরাইলি`,
    text: `ইজরাইলি`,
    value: `ইজরাইলি`,
  },
  {
    key: `জ্যামাইকান`,
    text: `জ্যামাইকান`,
    value: `জ্যামাইকান`,
  },
  {
    key: `জর্ডানিয়ান`,
    text: `জর্ডানিয়ান`,
    value: `জর্ডানিয়ান`,
  },
  {
    key: `কেনিয়ান`,
    text: `কেনিয়ান`,
    value: `কেনিয়ান`,
  },
  {
    key: `কুয়েতি`,
    text: `কুয়েতি`,
    value: `কুয়েতি`,
  },
  {
    key: `লাও`,
    text: `লাও`,
    value: `লাও`,
  },
  {
    key: `লাতভিয়ান`,
    text: `লাতভিয়ান`,
    value: `লাতভিয়ান`,
  },
  {
    key: `লেবানিজ`,
    text: `লেবানিজ`,
    value: `লেবানিজ`,
  },
  {
    key: `লিবিয়ান`,
    text: `লিবিয়ান`,
    value: `লিবিয়ান`,
  },
  {
    key: `লিথুয়ানিয়ান`,
    text: `লিথুয়ানিয়ান`,
    value: `লিথুয়ানিয়ান`,
  },
  {
    key: `মালিয়ান`,
    text: `মালিয়ান`,
    value: `মালিয়ান`,
  },
  {
    key: `মলতিজ`,
    text: `মলতিজ`,
    value: `মলতিজ`,
  },
  {
    key: `মেক্সিকান`,
    text: `মেক্সিকান`,
    value: `মেক্সিকান`,
  },
  {
    key: `মঙ্গোলিয়ান`,
    text: `মঙ্গোলিয়ান`,
    value: `মঙ্গোলিয়ান`,
  },
  {
    key: `মরক্কান`,
    text: `মরক্কান`,
    value: `মরক্কান`,
  },
  {
    key: `মোজাম্বিকান`,
    text: `মোজাম্বিকান`,
    value: `মোজাম্বিকান`,
  },
  {
    key: `নামিবিয়ান`,
    text: `নামিবিয়ান`,
    value: `নামিবিয়ান`,
  },
  {
    key: `নেপালিজ`,
    text: `নেপালিজ`,
    value: `নেপালিজ`,
  },
  {
    key: `নিউ জিল্যান্ড`,
    text: `নিউ জিল্যান্ড`,
    value: `নিউ জিল্যান্ড`,
  },
  {
    key: `নিকারাগুয়ান`,
    text: `নিকারাগুয়ান`,
    value: `নিকারাগুয়ান`,
  },
  {
    key: `বনাইজেরিয়ান`,
    text: `বনাইজেরিয়ান`,
    value: `বনাইজেরিয়ান`,
  },
  {
    key: `নরওয়েজিয়ান`,
    text: `নরওয়েজিয়ান`,
    value: `নরওয়েজিয়ান`,
  },
  {
    key: `পানামানিয়ান`,
    text: `পানামানিয়ান`,
    value: `পানামানিয়ান`,
  },
  {
    key: `পারাগুয়ান`,
    text: `পারাগুয়ান`,
    value: `পারাগুয়ান`,
  },
  {
    key: `সৌদি`,
    text: `সৌদি`,
    value: `সৌদি`,
  },
  {
    key: `আরজেন্টিনিয়ান`,
    text: `আরজেন্টিনিয়ান`,
    value: `আরজেন্টিনিয়ান`,
  },
  {
    key: `বতসওানা`,
    text: `বতসওানা`,
    value: `বতসওানা`,
  },
  {
    key: `বুলগেরিয়ান`,
    text: `বুলগেরিয়ান`,
    value: `বুলগেরিয়ান`,
  },
  {
    key: `ক্যাম্বডিয়ান`,
    text: `ক্যাম্বডিয়ান`,
    value: `ক্যাম্বডিয়ান`,
  },
  {
    key: `কামেরুনিয়ান`,
    text: `কামেরুনিয়ান`,
    value: `কামেরুনিয়ান`,
  },
  {
    key: `চিলিয়ান`,
    text: `চিলিয়ান`,
    value: `চিলিয়ান`,
  },
  {
    key: `কলম্বিয়ান`,
    text: `কলম্বিয়ান`,
    value: `কলম্বিয়ান`,
  },
  {
    key: `কোস্টা রিকান`,
    text: `কোস্টা রিকান`,
    value: `কোস্টা রিকান`,
  },
  {
    key: `ক্রশিয়ান`,
    text: `ক্রশিয়ান`,
    value: `ক্রশিয়ান`,
  },
  {
    key: `কিউবান`,
    text: `কিউবান`,
    value: `কিউবান`,
  },
  {
    key: `চেক`,
    text: `চেক`,
    value: `চেক`,
  },
  {
    key: `ডোমিনিকান`,
    text: `ডোমিনিকান`,
    value: `ডোমিনিকান`,
  },
  {
    key: `ইকুয়েডরিয়ান`,
    text: `ইকুয়েডরিয়ান`,
    value: `ইকুয়েডরিয়ান`,
  },
  {
    key: `সাল্ভাডরিয়ান`,
    text: `সাল্ভাডরিয়ান`,
    value: `সাল্ভাডরিয়ান`,
  },
  {
    key: `এস্টোনিয়ান`,
    text: `এস্টোনিয়ান`,
    value: `এস্টোনিয়ান`,
  },
]

export const gender = [
  {
    key: `পুরুষ`,
    text: `পুরুষ`,
    value: `পুরুষ`,
  },
  {
    key: `মহিলা`,
    text: `মহিলা`,
    value: `মহিলা`,
  },
  {
    key: `অন্যান্য`,
    text: `অন্যান্য`,
    value: `অন্যান্য`,
  },
]

export const maritialStatus = [
  {
    key: `বিবাহিত`,
    text: `বিবাহিত`,
    value: `বিবাহিত`,
  },
  {
    key: `অবিবাহিত`,
    text: `অবিবাহিত`,
    value: `অবিবাহিত`,
  },
  {
    key: `বিপত্নীক`,
    text: `বিপত্নীক`,
    value: `বিপত্নীক`,
  },
  {
    key: `বিধবা`,
    text: `বিধবা`,
    value: `বিধবা`,
  }
]

export const religion = [
  {
    key: `ইসলাম`,
    text: `ইসলাম`,
    value: `ইসলাম`,
  },
  {
    key: `খ্রীষ্টান`,
    text: `খ্রীষ্টান`,
    value: `খ্রীষ্টান`,
  },
  {
    key: `হিন্দু`,
    text: `হিন্দু`,
    value: `হিন্দু`,
  },
  {
    key: `বৌদ্ধ`,
    text: `বৌদ্ধ`,
    value: `বৌদ্ধ`,
  },
  {
    key: `অন্যান্য`,
    text: `অন্যান্য`,
    value: `অন্যান্য`,
  },
]

export const bankName = [
  {
    key: `অগ্রণী ব্যাংক লিমিটেড`,
    text: `অগ্রণী ব্যাংক লিমিটেড`,
    value: `অগ্রণী ব্যাংক লিমিটেড`,
  },
  {
    key: `আল-আরাফা ইসলামী ব্যাংক লিমিটেড`,
    text: `আল-আরাফা ইসলামী ব্যাংক লিমিটেড`,
    value: `আল-আরাফা ইসলামী ব্যাংক লিমিটেড`,
  },
  {
    key: `এবি ব্যাংক লিমিটেড`,
    text: `এবি ব্যাংক লিমিটেড`,
    value: `এবি ব্যাংক লিমিটেড`,
  },
  {
    key: `বাংলাদেশ কমার্স ব্যাংক লিমিটেড`,
    text: `বাংলাদেশ কমার্স ব্যাংক লিমিটেড`,
    value: `বাংলাদেশ কমার্স ব্যাংক লিমিটেড`,
  },
  {
    key: `বাংলাদেশ কৃষি ব্যাংক`,
    text: `বাংলাদেশ কৃষি ব্যাংক`,
    value: `বাংলাদেশ কৃষি ব্যাংক`,
  },
  {
    key: `বাংলাদেশ সমবায় ব্যাংক লিমিটেড`,
    text: `বাংলাদেশ সমবায় ব্যাংক লিমিটেড`,
    value: `বাংলাদেশ সমবায় ব্যাংক লিমিটেড`,
  },
  {
    key: `বেসিক ব্যাংক লিমিটেড`,
    text: `বেসিক ব্যাংক লিমিটেড`,
    value: `বেসিক ব্যাংক লিমিটেড`,
  },
  {
    key: `ব্র্যাক ব্যাংক লিমিটেড`,
    text: `ব্র্যাক ব্যাংক লিমিটেড`,
    value: `ব্র্যাক ব্যাংক লিমিটেড`,
  },
  {
    key: `ব্যাংক আল-ফালাহ লিমিটেড`,
    text: `ব্যাংক আল-ফালাহ লিমিটেড`,
    value: `ব্যাংক আল-ফালাহ লিমিটেড`,
  },
  {
    key: `ব্যাংক এশিয়া লিমিটেড`,
    text: `ব্যাংক এশিয়া লিমিটেড`,
    value: `ব্যাংক এশিয়া লিমিটেড`,
  },
  {
    key: `সিটি ব্যাংক এন এ`,
    text: `সিটি ব্যাংক এন এ`,
    value: `সিটি ব্যাংক এন এ`,
  },
  {
    key: `কমার্শিয়াল ব্যাংক অফ সেয়লন`,
    text: `কমার্শিয়াল ব্যাংক অফ সেয়লন`,
    value: `কমার্শিয়াল ব্যাংক অফ সেয়লন`,
  },
  {
    key: `ঢাকা ব্যাংক লিমিটেড`,
    text: `ঢাকা ব্যাংক লিমিটেড`,
    value: `ঢাকা ব্যাংক লিমিটেড`,
  },
  {
    key: `ডাচ-বাংলা ব্যাংক লিমিটেড`,
    text: `ডাচ-বাংলা ব্যাংক লিমিটেড`,
    value: `ডাচ-বাংলা ব্যাংক লিমিটেড`,
  },
  {
    key: `ইস্টার্ন ব্যাংক লিমিটেড`,
    text: `ইস্টার্ন ব্যাংক লিমিটেড`,
    value: `ইস্টার্ন ব্যাংক লিমিটেড`,
  },
  {
    key: `এক্সিম ব্যাংক লিমিটেড`,
    text: `এক্সিম ব্যাংক লিমিটেড`,
    value: `এক্সিম ব্যাংক লিমিটেড`,
  },
  {
    key: `ফার্স্ট সিকিউরিটি ইসলামী ব্যাংক লিমিটেড`,
    text: `ফার্স্ট সিকিউরিটি ইসলামী ব্যাংক লিমিটেড`,
    value: `ফার্স্ট সিকিউরিটি ইসলামী ব্যাংক লিমিটেড`,
  },
  {
    key: `হাবিব ব্যাংক লিমিটেড`,
    text: `হাবিব ব্যাংক লিমিটেড`,
    value: `হাবিব ব্যাংক লিমিটেড`,
  },
  {
    key: `হঙ্ককং এন্ড সাংহাই ব্যাংকিং কর্প`,
    text: `হঙ্ককং এন্ড সাংহাই ব্যাংকিং কর্প`,
    value: `হঙ্ককং এন্ড সাংহাই ব্যাংকিং কর্প`,
  },
  {
    key: `আইএফআইসি ব্যাংক লিমিটেড`,
    text: `আইএফআইসি ব্যাংক লিমিটেড`,
    value: `আইএফআইসি ব্যাংক লিমিটেড`,
  },
  {
    key: `ইসলামী ব্যাংক বাংলাদেশ লিমিটেড`,
    text: `ইসলামী ব্যাংক বাংলাদেশ লিমিটেড`,
    value: `ইসলামী ব্যাংক বাংলাদেশ লিমিটেড`,
  },
  {
    key: `যমুনা ব্যাংক লিমিটেড`,
    text: `যমুনা ব্যাংক লিমিটেড`,
    value: `যমুনা ব্যাংক লিমিটেড`,
  },
  {
    key: `জনতা ব্যাংক লিমিটেড`,
    text: `জনতা ব্যাংক লিমিটেড`,
    value: `জনতা ব্যাংক লিমিটেড`,
  },
  {
    key: `মারকেনটাইল ব্যাংক লিমিটেড`,
    text: `মারকেনটাইল ব্যাংক লিমিটেড`,
    value: `মারকেনটাইল ব্যাংক লিমিটেড`,
  },
  {
    key: `মিউচুয়াল ট্রাস্ট ব্যাংক লিমিটেড`,
    text: `মিউচুয়াল ট্রাস্ট ব্যাংক লিমিটেড`,
    value: `মিউচুয়াল ট্রাস্ট ব্যাংক লিমিটেড`,
  },
  {
    key: `ন্যাশনাল ব্যাংক লিমিটেড`,
    text: `ন্যাশনাল ব্যাংক লিমিটেড`,
    value: `ন্যাশনাল ব্যাংক লিমিটেড`,
  },
  {
    key: `ন্যাশনাল ব্যাংক অব পাকিস্তান`,
    text: `ন্যাশনাল ব্যাংক অব পাকিস্তান`,
    value: `ন্যাশনাল ব্যাংক অব পাকিস্তান`,
  },
  {
    key: `ন্যাশনাল ক্রেডিট এন্ড কমার্স ব্যাংক লিমিটেড`,
    text: `ন্যাশনাল ক্রেডিট এন্ড কমার্স ব্যাংক লিমিটেড`,
    value: `ন্যাশনাল ক্রেডিট এন্ড কমার্স ব্যাংক লিমিটেড`,
  },
  {
    key: `ওয়ান ব্যাংক লিমিটেড`,
    text: `ওয়ান ব্যাংক লিমিটেড`,
    value: `ওয়ান ব্যাংক লিমিটেড`,
  },
  {
    key: `প্রাইম ব্যাংক লিমিটেড`,
    text: `প্রাইম ব্যাংক লিমিটেড`,
    value: `প্রাইম ব্যাংক লিমিটেড`,
  },
  {
    key: `পূবালী ব্যাংক লিমিটেড`,
    text: `পূবালী ব্যাংক লিমিটেড`,
    value: `পূবালী ব্যাংক লিমিটেড`,
  },
  {
    key: `রাজশাহী কৃষি উন্নয়ন ব্যাংক`,
    text: `রাজশাহী কৃষি উন্নয়ন ব্যাংক`,
    value: `রাজশাহী কৃষি উন্নয়ন ব্যাংক`,
  },
  {
    key: `রুপালী ব্যাংক লিমিটেড`,
    text: `রুপালী ব্যাংক লিমিটেড`,
    value: `রুপালী ব্যাংক লিমিটেড`,
  },
  {
    key: `শাহ্‌জালাল ইসলামী ব্যাংক লিমিটেড`,
    text: `শাহ্‌জালাল ইসলামী ব্যাংক লিমিটেড`,
    value: `শাহ্‌জালাল ইসলামী ব্যাংক লিমিটেড`,
  },
  {
    key: `সোশ্যাল ইসলামী ব্যাংক লিমিটেড`,
    text: `সোশ্যাল ইসলামী ব্যাংক লিমিটেড`,
    value: `সোশ্যাল ইসলামী ব্যাংক লিমিটেড`,
  },
  {
    key: `সোনালী ব্যাংক লিমিটেড`,
    text: `সোনালী ব্যাংক লিমিটেড`,
    value: `সোনালী ব্যাংক লিমিটেড`,
  },
  {
    key: `সাউথইস্ট ব্যাংক লিমিটেড`,
    text: `সাউথইস্ট ব্যাংক লিমিটেড`,
    value: `সাউথইস্ট ব্যাংক লিমিটেড`,
  },
  {
    key: `স্ট্যান্ডার্ড ব্যাংক লিমিটেড`,
    text: `স্ট্যান্ডার্ড ব্যাংক লিমিটেড`,
    value: `স্ট্যান্ডার্ড ব্যাংক লিমিটেড`,
  },
  {
    key: `স্ট্যান্ডার্ড চার্টার্ড ব্যাংক`,
    text: `স্ট্যান্ডার্ড চার্টার্ড ব্যাংক`,
    value: `স্ট্যান্ডার্ড চার্টার্ড ব্যাংক`,
  },
  {
    key: `স্টেট ব্যাংক অফ ইন্ডিয়া`,
    text: `স্টেট ব্যাংক অফ ইন্ডিয়া`,
    value: `স্টেট ব্যাংক অফ ইন্ডিয়া`,
  },
  {
    key: `ডি সিটি ব্যাংক লিমিটেড`,
    text: `ডি সিটি ব্যাংক লিমিটেড`,
    value: `ডি সিটি ব্যাংক লিমিটেড`,
  },
  {
    key: `আইসিবি ইসলামী ব্যাংক লিমিটেড`,
    text: `আইসিবি ইসলামী ব্যাংক লিমিটেড`,
    value: `আইসিবি ইসলামী ব্যাংক লিমিটেড`,
  },
  {
    key: `ডি প্রিমিয়ার ব্যাংক লিমিটেড`,
    text: `ডি প্রিমিয়ার ব্যাংক লিমিটেড`,
    value: `ডি প্রিমিয়ার ব্যাংক লিমিটেড`,
  },
  {
    key: `ট্রাস্ট ব্যাংক লিমিটেড`,
    text: `ট্রাস্ট ব্যাংক লিমিটেড`,
    value: `ট্রাস্ট ব্যাংক লিমিটেড`,
  },
  {
    key: `ইউনাইটেড কমার্শিয়াল ব্যাংক লিমিটেড`,
    text: `ইউনাইটেড কমার্শিয়াল ব্যাংক লিমিটেড`,
    value: `ইউনাইটেড কমার্শিয়াল ব্যাংক লিমিটেড`,
  },
  {
    key: `উত্তরা ব্যাংক লিমিটেড`,
    text: `উত্তরা ব্যাংক লিমিটেড`,
    value: `উত্তরা ব্যাংক লিমিটেড`,
  },
  {
    key: `উরি ব্যাংক`,
    text: `উরি ব্যাংক`,
    value: `উরি ব্যাংক`,
  },
  {
    key: `এনআরবি কমার্শিয়াল ব্যাংক লিমিটেড`,
    text: `এনআরবি কমার্শিয়াল ব্যাংক লিমিটেড`,
    value: `এনআরবি কমার্শিয়াল ব্যাংক লিমিটেড`,
  },
  {
    key: `ইউনিয়ন ব্যাংক লিমিটেড`,
    text: `ইউনিয়ন ব্যাংক লিমিটেড`,
    value: `ইউনিয়ন ব্যাংক লিমিটেড`,
  },
  {
    key: `এসবিএসি ব্যাংক লিমিটেড`,
    text: `এসবিএসি ব্যাংক লিমিটেড`,
    value: `এসবিএসি ব্যাংক লিমিটেড`,
  },
  {
    key: `মেঘনা ব্যাংক লিমিটেড`,
    text: `মেঘনা ব্যাংক লিমিটেড`,
    value: `মেঘনা ব্যাংক লিমিটেড`,
  },
  {
    key: `পদ্মা ব্যাংক লিমিটেড`,
    text: `পদ্মা ব্যাংক লিমিটেড`,
    value: `পদ্মা ব্যাংক লিমিটেড`,
  },
  {
    key: `মিডল্যান্ড ব্যাংক লিমিটেড`,
    text: `মিডল্যান্ড ব্যাংক লিমিটেড`,
    value: `মিডল্যান্ড ব্যাংক লিমিটেড`,
  },
  {
    key: `এনআরবি ব্যাংক লিমিটেড`,
    text: `এনআরবি ব্যাংক লিমিটেড`,
    value: `এনআরবি ব্যাংক লিমিটেড`,
  },
  {
    key: `মধুমতী ব্যাংক লিমিটেড`,
    text: `মধুমতী ব্যাংক লিমিটেড`,
    value: `মধুমতী ব্যাংক লিমিটেড`,
  },
  {
    key: `এনআরবি গ্লোবাল ব্যাংকলিমিটেড`,
    text: `এনআরবি গ্লোবাল ব্যাংকলিমিটেড`,
    value: `এনআরবি গ্লোবাল ব্যাংকলিমিটেড`,
  },
  {
    key: `সীমান্ত ব্যাংক লিমিটেড`,
    text: `সীমান্ত ব্যাংক লিমিটেড`,
    value: `সীমান্ত ব্যাংক লিমিটেড`,
  },
]

export const countries = [
  { key: "বাংলাদেশ", text: "বাংলাদেশ", value: "বাংলাদেশ" },
  { key: "আফগানিস্তান", text: "আফগানিস্তান", value: "আফগানিস্তান" },
  { key: "আলবেনিয়া", text: "আলবেনিয়া", value: "আলবেনিয়া" },
  { key: "আলজেরিয়া", text: "আলজেরিয়া", value: "আলজেরিয়া" },
  { key: "এঁদোরা", text: "এঁদোরা", value: "এঁদোরা" },
  { key: "এঙ্গোলা", text: "এঙ্গোলা", value: "এঙ্গোলা" },
  { key: "এন্টিগুয়া এন্ড বারবুডা", text: "এন্টিগুয়া এন্ড বারবুডা", value: "এন্টিগুয়া এন্ড বারবুডা" },
  { key: "আর্জেন্টিনা", text: "আর্জেন্টিনা", value: "আর্জেন্টিনা" },
  { key: "আর্মেনিয়া", text: "আর্মেনিয়া", value: "আর্মেনিয়া" },
  { key: "অস্ট্রেলিয়া", text: "অস্ট্রেলিয়া", value: "অস্ট্রেলিয়া" },
  { key: "অস্ট্রিয়া", text: "অস্ট্রিয়া", value: "অস্ট্রিয়া" },
  { key: "আজারবাইজান", text: "আজারবাইজান", value: "আজারবাইজান" },
  { key: "বাহামাজ", text: "বাহামাজ", value: "বাহামাজ" },
  { key: "বাহরাইন", text: "বাহরাইন", value: "বাহরাইন" },
  { key: "বার্বাডোস", text: "বার্বাডোস", value: "বার্বাডোস" },
  { key: "বেলারুশ", text: "বেলারুশ", value: "বেলারুশ" },
  { key: "বেলজিয়াম", text: "বেলজিয়াম", value: "বেলজিয়াম" },
  { key: "বেলিজ", text: "বেলিজ", value: "বেলিজ" },
  { key: "বেনিন", text: "বেনিন", value: "বেনিন" },
  { key: "ভুটান", text: "ভুটান", value: "ভুটান" },
  { key: "বলিভিয়া", text: "বলিভিয়া", value: "বলিভিয়া" },
  { key: "বসনিয়া হারজেগভিয়া", text: "বসনিয়া হারজেগভিয়া", value: "বসনিয়া হারজেগভিয়া" },
  { key: "বোতসওয়ানা", text: "বোতসওয়ানা", value: "বোতসওয়ানা" },
  { key: "ব্রাজিল", text: "ব্রাজিল", value: "ব্রাজিল" },
  { key: "ব্রুনেই", text: "ব্রুনেই", value: "ব্রুনেই" },
  { key: "যাও", text: "যাও", value: "যাও" },
  { key: "বুলগেরিয়া", text: "বুলগেরিয়া", value: "বুলগেরিয়া" },
  { key: "বুরকিনা ফাসো", text: "বুরকিনা ফাসো", value: "বুরকিনা ফাসো" },
  { key: "বুরন্দি", text: "বুরন্দি", value: "বুরন্দি" },
  { key: "আইভরি কোস্ট", text: "আইভরি কোস্ট", value: "আইভরি কোস্ট" },
  { key: "কোবে ভার্দে", text: "কোবে ভার্দে", value: "কোবে ভার্দে" },
  { key: "ক্যাম্বডিয়া", text: "ক্যাম্বডিয়া", value: "ক্যাম্বডিয়া" },
  { key: "ক্যামেরুন", text: "ক্যামেরুন", value: "ক্যামেরুন" },
  { key: "কানাডা", text: "কানাডা", value: "কানাডা" },
  { key: "সেন্ট্রাল আফ্রিকান রিপাবলিক", text: "সেন্ট্রাল আফ্রিকান রিপাবলিক", value: "সেন্ট্রাল আফ্রিকান রিপাবলিক" },
  { key: "চাদ", text: "চাদ", value: "চাদ" },
  { key: "চিলি", text: "চিলি", value: "চিলি" },
  { key: "চীন", text: "চীন", value: "চীন" },
  { key: "কলোম্বিয়া", text: "কলোম্বিয়া", value: "কলোম্বিয়া" },
  { key: "কমরস", text: "কমরস", value: "কমরস" },
  { key: "কঙ্গো", text: "কঙ্গো", value: "কঙ্গো" },
  { key: "কোস্টা রিকা", text: "কোস্টা রিকা", value: "কোস্টা রিকা" },
  { key: "ক্রসিয়া", text: "ক্রসিয়া", value: "ক্রসিয়া" },
  { key: "কিউবা", text: "কিউবা", value: "কিউবা" },
  { key: "সাইপ্রাস", text: "সাইপ্রাস", value: "সাইপ্রাস" },
  { key: "চেক রিপাবলিক", text: "চেক রিপাবলিক", value: "চেক রিপাবলিক" },
  { key: "কঙ্গো গনপ্রজাতন্ত্র", text: "কঙ্গো গনপ্রজাতন্ত্র", value: "কঙ্গো গনপ্রজাতন্ত্র" },
  { key: "ডেনমার্ক", text: "ডেনমার্ক", value: "ডেনমার্ক" },
  { key: "জিবুতি", text: "জিবুতি", value: "জিবুতি" },
  { key: "ডোমিনিকা", text: "ডোমিনিকা", value: "ডোমিনিকা" },
  { key: "ডোমিনিকান প্রজাতন্ত্র", text: "ডোমিনিকান প্রজাতন্ত্র", value: "ডোমিনিকান প্রজাতন্ত্র" },
  { key: "একুয়াডোর", text: "একুয়াডোর", value: "একুয়াডোর" },
  { key: "মিশর", text: "মিশর", value: "মিশর" },
  { key: "এল সাল্ভাদর", text: "এল সাল্ভাদর", value: "এল সাল্ভাদর" },
  { key: "নিরক্ষীয় গিনি", text: "নিরক্ষীয় গিনি", value: "নিরক্ষীয় গিনি" },
  { key: "ইরিত্রিয়া", text: "ইরিত্রিয়া", value: "ইরিত্রিয়া" },
  { key: "এস্তোনিয়া", text: "এস্তোনিয়া", value: "এস্তোনিয়া" },
  { key: "সোয়াজিল্যান্ডে", text: "সোয়াজিল্যান্ডে", value: "সোয়াজিল্যান্ডে" },
  { key: "ইথিওপিয়া", text: "ইথিওপিয়া", value: "ইথিওপিয়া" },
  { key: "ফিজি", text: "ফিজি", value: "ফিজি" },
  { key: "ফিনল্যান্ড", text: "ফিনল্যান্ড", value: "ফিনল্যান্ড" },
  { key: "ফ্রান্স", text: "ফ্রান্স", value: "ফ্রান্স" },
  { key: "গাবন", text: "গাবন", value: "গাবন" },
  { key: "গাম্বিয়া", text: "গাম্বিয়া", value: "গাম্বিয়া" },
  { key: "জর্জিয়া", text: "জর্জিয়া", value: "জর্জিয়া" },
  { key: "জার্মানি", text: "জার্মানি", value: "জার্মানি" },
  { key: "ঘানা", text: "ঘানা", value: "ঘানা" },
  { key: "গ্রীস", text: "গ্রীস", value: "গ্রীস" },
  { key: "গ্রেনাডা", text: "গ্রেনাডা", value: "গ্রেনাডা" },
  { key: "গুয়াতেমালা", text: "গুয়াতেমালা", value: "গুয়াতেমালা" },
  { key: "গিনি", text: "গিনি", value: "গিনি" },
  { key: "গিনি-বিসাউ", text: "গিনি-বিসাউ", value: "গিনি-বিসাউ" },
  { key: "গিয়ানা", text: "গিয়ানা", value: "গিয়ানা" },
  { key: "হাইতি", text: "হাইতি", value: "হাইতি" },
  { key: "ভ্যাটিকান", text: "ভ্যাটিকান", value: "ভ্যাটিকান" },
  { key: "হন্ডুরাস", text: "হন্ডুরাস", value: "হন্ডুরাস" },
  { key: "হাঙ্গেরি", text: "হাঙ্গেরি", value: "হাঙ্গেরি" },
  { key: "আইসল্যান্ড", text: "আইসল্যান্ড", value: "আইসল্যান্ড" },
  { key: "ভারত", text: "ভারত", value: "ভারত" },
  { key: "ইন্দোনেশিয়া", text: "ইন্দোনেশিয়া", value: "ইন্দোনেশিয়া" },
  { key: "ইরান", text: "ইরান", value: "ইরান" },
  { key: "ইরাক", text: "ইরাক", value: "ইরাক" },
  { key: "আয়ারল্যান্ড", text: "আয়ারল্যান্ড", value: "আয়ারল্যান্ড" },
  { key: "ইযরায়েল", text: "ইযরায়েল", value: "ইযরায়েল" },
  { key: "ইতালি", text: "ইতালি", value: "ইতালি" },
  { key: "জামাইকা", text: "জামাইকা", value: "জামাইকা" },
  { key: "জাপান", text: "জাপান", value: "জাপান" },
  { key: "জর্দান", text: "জর্দান", value: "জর্দান" },
  { key: "কাজাখস্তান", text: "কাজাখস্তান", value: "কাজাখস্তান" },
  { key: "কেনিয়া", text: "কেনিয়া", value: "কেনিয়া" },
  { key: "কিরিবাতি", text: "কিরিবাতি", value: "কিরিবাতি" },
  { key: "কুয়েত", text: "কুয়েত", value: "কুয়েত" },
  { key: "কিরগিজস্তান", text: "কিরগিজস্তান", value: "কিরগিজস্তান" },
  { key: "লাওস", text: "লাওস", value: "লাওস" },
  { key: "লাটভিয়া", text: "লাটভিয়া", value: "লাটভিয়া" },
  { key: "লেবানন", text: "লেবানন", value: "লেবানন" },
  { key: "লেসোথো", text: "লেসোথো", value: "লেসোথো" },
  { key: "লাইবেরিয়া", text: "লাইবেরিয়া", value: "লাইবেরিয়া" },
  { key: "লিবিয়া", text: "লিবিয়া", value: "লিবিয়া" },
  { key: "লিচেনস্টেইন", text: "লিচেনস্টেইন", value: "লিচেনস্টেইন" },
  { key: "লিথুয়ানিয়া", text: "লিথুয়ানিয়া", value: "লিথুয়ানিয়া" },
  { key: "লাক্সেমবার্গ", text: "লাক্সেমবার্গ", value: "লাক্সেমবার্গ" },
  { key: "মাদাগাস্কার", text: "মাদাগাস্কার", value: "মাদাগাস্কার" },
  { key: "মালাউই", text: "মালাউই", value: "মালাউই" },
  { key: "মালয়েশিয়া", text: "মালয়েশিয়া", value: "মালয়েশিয়া" },
  { key: "মালদ্বীপ", text: "মালদ্বীপ", value: "মালদ্বীপ" },
  { key: "মালি", text: "মালি", value: "মালি" },
  { key: "মাল্টা", text: "মাল্টা", value: "মাল্টা" },
  { key: "মার্শাল দ্বীপপুঞ্জ", text: "মার্শাল দ্বীপপুঞ্জ", value: "মার্শাল দ্বীপপুঞ্জ" },
  { key: "মরিতানিয়া", text: "মরিতানিয়া", value: "মরিতানিয়া" },
  { key: "মরিশাস", text: "মরিশাস", value: "মরিশাস" },
  { key: "মেক্সিকো", text: "মেক্সিকো", value: "মেক্সিকো" },
  { key: "মাইক্রোনেশিয়া", text: "মাইক্রোনেশিয়া", value: "মাইক্রোনেশিয়া" },
  { key: "মোল্দাভিয়া", text: "মোল্দাভিয়া", value: "মোল্দাভিয়া" },
  { key: "মোনাকো", text: "মোনাকো", value: "মোনাকো" },
  { key: "মঙ্গোলিয়া", text: "মঙ্গোলিয়া", value: "মঙ্গোলিয়া" },
  { key: "মন্টিনিগ্রো", text: "মন্টিনিগ্রো", value: "মন্টিনিগ্রো" },
  { key: "মরক্কো", text: "মরক্কো", value: "মরক্কো" },
  { key: "মোজাম্বিক", text: "মোজাম্বিক", value: "মোজাম্বিক" },
  { key: "মায়ানমা", text: "মায়ানমা", value: "মায়ানমা" },
  { key: "নামিবিয়া", text: "নামিবিয়া", value: "নামিবিয়া" },
  { key: "নাউরু", text: "নাউরু", value: "নাউরু" },
  { key: "নেপাল", text: "নেপাল", value: "নেপাল" },
  { key: "নেদারল্যান্ডস", text: "নেদারল্যান্ডস", value: "নেদারল্যান্ডস" },
  { key: "নিউজিল্যান্ড", text: "নিউজিল্যান্ড", value: "নিউজিল্যান্ড" },
  { key: "নিকারাগুয়া", text: "নিকারাগুয়া", value: "নিকারাগুয়া" },
  { key: "নাইজার", text: "নাইজার", value: "নাইজার" },
  { key: "নাইজেরিয়া", text: "নাইজেরিয়া", value: "নাইজেরিয়া" },
  { key: "উত্তর কোরিয়া", text: "উত্তর কোরিয়া", value: "উত্তর কোরিয়া" },
  { key: "উত্তর ম্যাসেডোনিয়া", text: "উত্তর ম্যাসেডোনিয়া", value: "উত্তর ম্যাসেডোনিয়া" },
  { key: "নরওয়ে", text: "নরওয়ে", value: "নরওয়ে" },
  { key: "ওমান", text: "ওমান", value: "ওমান" },
  { key: "পাকিস্তান", text: "পাকিস্তান", value: "পাকিস্তান" },
  { key: "পালাও", text: "পালাও", value: "পালাও" },
  { key: "ফিলিস্তিন রাজ্য", text: "ফিলিস্তিন রাজ্য", value: "ফিলিস্তিন রাজ্য" },
  { key: "পানামা", text: "পানামা", value: "পানামা" },
  { key: "পাপুয়া নিউ গিনি", text: "পাপুয়া নিউ গিনি", value: "পাপুয়া নিউ গিনি" },
  { key: "প্যারাগুয়ে", text: "প্যারাগুয়ে", value: "প্যারাগুয়ে" },
  { key: "পেরু", text: "পেরু", value: "পেরু" },
  { key: "ফিলিপিন্স", text: "ফিলিপিন্স", value: "ফিলিপিন্স" },
  { key: "পোল্যান্ড", text: "পোল্যান্ড", value: "পোল্যান্ড" },
  { key: "পর্তুগাল", text: "পর্তুগাল", value: "পর্তুগাল" },
  { key: "কাতার", text: "কাতার", value: "কাতার" },
  { key: "রোমানিয়া", text: "রোমানিয়া", value: "রোমানিয়া" },
  { key: "রাশিয়া", text: "রাশিয়া", value: "রাশিয়া" },
  { key: "রুয়ান্ডা", text: "রুয়ান্ডা", value: "রুয়ান্ডা" },
  { key: "সেন্ট কিটস ও নেভিস", text: "সেন্ট কিটস ও নেভিস", value: "সেন্ট কিটস ও নেভিস" },
  { key: "সেন্ট লুসিয়া", text: "সেন্ট লুসিয়া", value: "সেন্ট লুসিয়া" },
  { key: "সেন্ট ভিনসেন্ট ও গ্রেনাডাইন দ্বীপপুঞ্জ", text: "সেন্ট ভিনসেন্ট ও গ্রেনাডাইন দ্বীপপুঞ্জ", value: "সেন্ট ভিনসেন্ট ও গ্রেনাডাইন দ্বীপপুঞ্জ" },
  { key: "সামোয়া", text: "সামোয়া", value: "সামোয়া" },
  { key: "সান মারিনো", text: "সান মারিনো", value: "সান মারিনো" },
  { key: "সাও টোমে এবং প্রিনসিপে", text: "সাও টোমে এবং প্রিনসিপে", value: "সাও টোমে এবং প্রিনসিপে" },
  { key: "সৌদি আরব", text: "সৌদি আরব", value: "সৌদি আরব" },
  { key: "সেনেগাল", text: "সেনেগাল", value: "সেনেগাল" },
  { key: "সার্বিয়া", text: "সার্বিয়া", value: "সার্বিয়া" },
  { key: "সেশেলস", text: "সেশেলস", value: "সেশেলস" },
  { key: "সিয়েরা লিওন", text: "সিয়েরা লিওন", value: "সিয়েরা লিওন" },
  { key: "সিঙ্গাপুর", text: "সিঙ্গাপুর", value: "সিঙ্গাপুর" },
  { key: "স্লোভাকিয়া", text: "স্লোভাকিয়া", value: "স্লোভাকিয়া" },
  { key: "স্লোভেনিয়া", text: "স্লোভেনিয়া", value: "স্লোভেনিয়া" },
  { key: "সলোমান দ্বীপপুঞ্জ", text: "সলোমান দ্বীপপুঞ্জ", value: "সলোমান দ্বীপপুঞ্জ" },
  { key: "সোমালিয়া", text: "সোমালিয়া", value: "সোমালিয়া" },
  { key: "দক্ষিন আফ্রিকা", text: "দক্ষিন আফ্রিকা", value: "দক্ষিন আফ্রিকা" },
  { key: "দক্ষিণ কোরিয়া", text: "দক্ষিণ কোরিয়া", value: "দক্ষিণ কোরিয়া" },
  { key: "দক্ষিণ সুদান", text: "দক্ষিণ সুদান", value: "দক্ষিণ সুদান" },
  { key: "স্পেন", text: "স্পেন", value: "স্পেন" },
  { key: "শ্রীলংকা", text: "শ্রীলংকা", value: "শ্রীলংকা" },
  { key: "সুদান", text: "সুদান", value: "সুদান" },
  { key: "সুরিনাম", text: "সুরিনাম", value: "সুরিনাম" },
  { key: "সুইডেন", text: "সুইডেন", value: "সুইডেন" },
  { key: "সুইজারল্যান্ড", text: "সুইজারল্যান্ড", value: "সুইজারল্যান্ড" },
  { key: "সিরিয়া", text: "সিরিয়া", value: "সিরিয়া" },
  { key: "তাজিকিস্তান", text: "তাজিকিস্তান", value: "তাজিকিস্তান" },
  { key: "তানজানিয়া", text: "তানজানিয়া", value: "তানজানিয়া" },
  { key: "থাইল্যান্ড", text: "থাইল্যান্ড", value: "থাইল্যান্ড" },
  { key: "টিমোর-লেস্টে", text: "টিমোর-লেস্টে", value: "টিমোর-লেস্টে" },
  { key: "টঙ্গা", text: "টঙ্গা", value: "টঙ্গা" },
  { key: "ত্রিনিদাদ ও টোবাগো", text: "ত্রিনিদাদ ও টোবাগো", value: "ত্রিনিদাদ ও টোবাগো" },
  { key: "তিউনিসিয়া", text: "তিউনিসিয়া", value: "তিউনিসিয়া" },
  { key: "তুরস্ক", text: "তুরস্ক", value: "তুরস্ক" },
  { key: "তুর্কমেনিস্তান", text: "তুর্কমেনিস্তান", value: "তুর্কমেনিস্তান" },
  { key: "টুভালু", text: "টুভালু", value: "টুভালু" },
  { key: "উগান্ডা", text: "উগান্ডা", value: "উগান্ডা" },
  { key: "ইউক্রেন", text: "ইউক্রেন", value: "ইউক্রেন" },
  { key: "সংযুক্ত আরব আমিরাত", text: "সংযুক্ত আরব আমিরাত", value: "সংযুক্ত আরব আমিরাত" },
  { key: "যুক্তরাজ্য", text: "যুক্তরাজ্য", value: "যুক্তরাজ্য" },
  { key: "মার্কিন যুক্তরাষ্ট্র", text: "মার্কিন যুক্তরাষ্ট্র", value: "মার্কিন যুক্তরাষ্ট্র" },
  { key: "উরুগুয়ে", text: "উরুগুয়ে", value: "উরুগুয়ে" },
  { key: "উজবেকিস্তান", text: "উজবেকিস্তান", value: "উজবেকিস্তান" },
  { key: "ভানুয়াতু", text: "ভানুয়াতু", value: "ভানুয়াতু" },
  { key: "ভেনিজুয়েলা", text: "ভেনিজুয়েলা", value: "ভেনিজুয়েলা" },
  { key: "ভিয়েতনাম", text: "ভিয়েতনাম", value: "ভিয়েতনাম" },
  { key: "ইয়ামেন", text: "ইয়ামেন", value: "ইয়ামেন" },
  { key: "জাম্বিয়া", text: "জাম্বিয়া", value: "জাম্বিয়া" },
  { key: "জিম্বাবুয়ে", text: "জিম্বাবুয়ে", value: "জিম্বাবুয়ে" }
]